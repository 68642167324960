.root {
    position: relative;
    display: grid;
    padding-bottom: calc(3 * var(--spacing));
    gap: calc(6 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    @media (--lgUp) {
        padding-bottom: calc(6 * var(--spacing));
        grid-template-columns: 8fr 4fr;
    }

    @media (--xlUp) {
        grid-template-columns: 9fr 3fr;
    }

    @media (--ulUp) {
        gap: calc(18 * var(--spacing));
    }
}

.description {
    min-width: 0;
    grid-column: 1;
    grid-row: 2;

    @media (--lgUp) {
        grid-column: 1;
        grid-row: 1;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
    grid-column: 1;
    grid-row: 1;

    @media (--lgUp) {
        gap: calc(6 * var(--spacing));
        grid-column: 2;
        grid-row: 1;
    }
}

.anchor {
    position: absolute;
    top: calc(-6 * var(--spacing));
}
