.mastheadMedia {
    background: var(--gray7);

    @media (--mdUp) {
        overflow: hidden;
        border-radius: 0 0 8px 8px;
    }

    @media (--lgUp) {
        &.default {
            border-radius: 8px;
        }
    }
}

.flexCol {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

form.flexCol > :last-child:not(:only-child) {
    margin-top: calc(2 * var(--spacing));
}

.flexColStart {
    composes: flexCol;
    align-items: flex-start;
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
    gap: calc(2 * var(--spacing));
}

.twoCol {
    display: grid;
    gap: calc(2 * var(--spacing));
    grid-template-columns: 1fr 1fr;
}

.darkBackground {
    background: var(--gray5);
}

.icons {
    & > :nth-child(2) {
        color: var(--seablue);
    }

    & > :nth-child(3) {
        color: var(--yellow);
    }

    & > :nth-child(4) {
        color: var(--purple);
    }

    & > :nth-child(5) {
        color: var(--turquoise);
    }

    & > :nth-child(6) {
        color: var(--bubblegum);
    }
}

.collapsible {
    gap: calc(2 * var(--spacing));
}

.bottomBar,
.sidePanel {
    padding: calc(3 * var(--spacing));
}
