.root {
    display: flex;
    flex-direction: column;
    gap: calc(6 * var(--spacing));

    .speakers {
        display: grid;
        gap: calc(6 * var(--spacing));
        grid-template-columns: 1fr;
        justify-items: center;

        @media (--mdUp) {
            grid-template-columns: 1fr 1fr;
        }

        @media (--lgUp) {
            gap: calc(9 * var(--spacing));
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--ulUp) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
