.participants {
    display: grid;
    gap: calc(3 * var(--spacing));
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    @media (--lgUp) {
        gap: calc(6 * var(--spacing));
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--ulUp) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.loadMoreButton {
    margin-top: calc(6 * var(--spacing));
}

.overlay {
    color: var(--primaryColor);
}
