.label {
    margin-bottom: calc(0.5 * var(--spacing));
    color: var(--gray2);
}

.tagButtons {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing);
}
