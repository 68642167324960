.root {
    display: grid;
    gap: calc(2 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    @media (--smUp) {
        grid-template-columns: 1fr 1fr;
    }
}

.colspan {
    grid-column: 1 / -1;
}
