.container {
    display: grid;
    margin-bottom: calc(3 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    @media (--mdUp) {
        grid-template-columns: 2fr 8fr 2fr;

        & > * {
            grid-area: auto / 2;
        }
    }

    @media (--lgUp) {
        margin-bottom: calc(6 * var(--spacing));
    }
}
