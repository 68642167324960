.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: calc(2 * var(--spacing));
    gap: calc(0.5 * var(--spacing));

    @media (--smUp) {
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        gap: calc(2 * var(--spacing));
    }
}

.cards {
    display: grid;
    gap: calc(3 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    & > :nth-child(n + 2) {
        display: none;
    }

    @media (--mdUp) {
        grid-template-columns: 1fr 1fr;

        & > :nth-child(-n + 3) {
            display: flex;
        }

        & > :nth-child(n + 3) {
            display: none;
        }
    }

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr 1fr;

        & > :nth-child(-n + 4) {
            display: flex;
        }

        & > :nth-child(n + 4) {
            display: none;
        }
    }

    @media (--xlUp) {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        & > :nth-child(n + 1) {
            display: flex;
        }
    }
}
