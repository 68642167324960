@define-mixin standard {
    font-size: calc(1rem / 16 * 18);
    font-weight: 300;
    line-height: 24px;
}

@define-mixin small {
    font-size: 1rem;
    font-weight: 300;
    line-height: 24px;
}

@define-mixin smaller {
    font-size: calc(1rem / 16 * 14);
    font-weight: 400;
    line-height: 18px;
}

@define-mixin h1 {
    font-size: calc(1rem / 16 * 32);
    font-weight: 400;
    line-height: 40px;

    &.notResponsive {
        font-size: calc(1rem / 16 * 40);
        line-height: 48px;
    }

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 40);
        line-height: 48px;
    }
}

@define-mixin h2 {
    font-size: calc(1rem / 16 * 28);
    font-weight: 400;
    line-height: 32px;

    &.notResponsive {
        font-size: calc(1rem / 16 * 32);
        line-height: 40px;
    }

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 32);
        line-height: 40px;
    }
}

@define-mixin h3 {
    font-size: calc(1rem / 16 * 24);
    font-weight: 400;
    line-height: 32px;

    &.notResponsive {
        font-size: calc(1rem / 16 * 28);
    }

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 28);
    }
}

@define-mixin h4 {
    font-size: calc(1rem / 16 * 20);
    font-weight: 400;
    line-height: 24px;

    &.notResponsive {
        font-size: calc(1rem / 16 * 24);
        line-height: 32px;
    }

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 24);
        line-height: 32px;
    }
}

@define-mixin h5 {
    font-size: calc(1rem / 16 * 18);
    font-weight: 600;
    line-height: 24px;
}

@define-mixin h6 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
}

@define-mixin d1 {
    font-size: calc(1rem / 16 * 54);
    font-weight: 400;
    line-height: 64px;

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 70);
        line-height: 80px;
    }
}

@define-mixin d2 {
    font-size: calc(1rem / 16 * 48);
    font-weight: 400;
    line-height: 56px;

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 54);
        line-height: 64px;
    }
}

@define-mixin d3 {
    font-size: calc(1rem / 16 * 40);
    font-weight: 400;
    line-height: 48px;

    @media (--lgUp) {
        font-size: calc(1rem / 16 * 48);
        line-height: 56px;
    }
}

@define-mixin left {
    text-align: left;
}

@define-mixin right {
    text-align: right;
}

@define-mixin center {
    text-align: center;
}

@define-mixin justify {
    text-align: justify;
}

@define-mixin w300 {
    font-weight: 300;
}

@define-mixin w400 {
    font-weight: 400;
}

@define-mixin w500 {
    font-weight: 500;
}

@define-mixin w600 {
    font-weight: 600;
}

@define-mixin w700 {
    font-weight: 700;
}

@define-mixin noMargin {
    margin: 0;
}

@define-mixin noWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.standard {
    @mixin standard;
}

.small {
    @mixin small;
}

.smaller {
    @mixin smaller;
}

.h1 {
    @mixin h1;
}

.h2 {
    @mixin h2;
}

.h3 {
    @mixin h3;
}

.h4 {
    @mixin h4;
}

.h5 {
    @mixin h5;
}

.h6 {
    @mixin h6;
}

.d1 {
    @mixin d1;
}

.d2 {
    @mixin d2;
}

.d3 {
    @mixin d3;
}

@media (--smUp) {
    .smStandard {
        @mixin standard;
    }

    .smSmall {
        @mixin small;
    }

    .smSmaller {
        @mixin smaller;
    }

    .smH1 {
        @mixin h1;
    }

    .smH2 {
        @mixin h2;
    }

    .smH3 {
        @mixin h3;
    }

    .smH4 {
        @mixin h4;
    }

    .smH5 {
        @mixin h5;
    }

    .smH6 {
        @mixin h6;
    }

    .smD1 {
        @mixin d1;
    }

    .smD2 {
        @mixin d2;
    }

    .smD3 {
        @mixin d3;
    }
}

@media (--mdUp) {
    .mdStandard {
        @mixin standard;
    }

    .mdSmall {
        @mixin small;
    }

    .mdSmaller {
        @mixin smaller;
    }

    .mdH1 {
        @mixin h1;
    }

    .mdH2 {
        @mixin h2;
    }

    .mdH3 {
        @mixin h3;
    }

    .mdH4 {
        @mixin h4;
    }

    .mdH5 {
        @mixin h5;
    }

    .mdH6 {
        @mixin h6;
    }

    .mdD1 {
        @mixin d1;
    }

    .mdD2 {
        @mixin d2;
    }

    .mdD3 {
        @mixin d3;
    }
}

@media (--lgUp) {
    .lgStandard {
        @mixin standard;
    }

    .lgSmall {
        @mixin small;
    }

    .lgSmaller {
        @mixin smaller;
    }

    .lgH1 {
        @mixin h1;
    }

    .lgH2 {
        @mixin h2;
    }

    .lgH3 {
        @mixin h3;
    }

    .lgH4 {
        @mixin h4;
    }

    .lgH5 {
        @mixin h5;
    }

    .lgH6 {
        @mixin h6;
    }

    .lgD1 {
        @mixin d1;
    }

    .lgD2 {
        @mixin d2;
    }

    .lgD3 {
        @mixin d3;
    }
}

@media (--xlUp) {
    .xlStandard {
        @mixin standard;
    }

    .xlSmall {
        @mixin small;
    }

    .xlSmaller {
        @mixin smaller;
    }

    .xlH1 {
        @mixin h1;
    }

    .xlH2 {
        @mixin h2;
    }

    .xlH3 {
        @mixin h3;
    }

    .xlH4 {
        @mixin h4;
    }

    .xlH5 {
        @mixin h5;
    }

    .xlH6 {
        @mixin h6;
    }

    .xlD1 {
        @mixin d1;
    }

    .xlD2 {
        @mixin d2;
    }

    .xlD3 {
        @mixin d3;
    }
}

@media (--ulUp) {
    .ulStandard {
        @mixin standard;
    }

    .ulSmall {
        @mixin small;
    }

    .ulSmaller {
        @mixin smaller;
    }

    .ulH1 {
        @mixin h1;
    }

    .ulH2 {
        @mixin h2;
    }

    .ulH3 {
        @mixin h3;
    }

    .ulH4 {
        @mixin h4;
    }

    .ulH5 {
        @mixin h5;
    }

    .ulH6 {
        @mixin h6;
    }

    .ulD1 {
        @mixin d1;
    }

    .ulD2 {
        @mixin d2;
    }

    .ulD3 {
        @mixin d3;
    }
}

.left {
    @mixin left;
}

.right {
    @mixin right;
}

.center {
    @mixin center;
}

.justify {
    @mixin justify;
}

.w300 {
    @mixin w300;
}

.w400 {
    @mixin w400;
}

.w500 {
    @mixin w500;
}

.w600 {
    @mixin w600;
}

.w700 {
    @mixin w700;
}

.noMargin {
    @mixin noMargin;
}

.noWrap {
    @mixin noWrap;
}
