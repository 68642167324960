.root {
    padding-bottom: 0;
}

.container {
    display: grid;
    margin-bottom: calc(6 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    @media (--mdUp) {
        grid-template-columns: 2.5fr 7fr 2.5fr;

        & > * {
            grid-area: auto / 2;
        }
    }
}

.backLink {
    margin-bottom: calc(2 * var(--spacing));
}
