.skeleton {
    height: 200px;

    @media (--lgUp) {
        height: 216px;
    }

    @media (--xlUp) {
        height: 224px;
    }

    @media (--ulUp) {
        height: 136px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));

    .actions {
        justify-content: center;
        text-align: center;
    }

    &.default {
        @media (--lgUp) {
            gap: calc(2 * var(--spacing));

            .information {
                text-align: left;
            }
        }

        @media (--xlUp) {
            gap: calc(3 * var(--spacing));
        }

        @media (--ulUp) {
            .actions {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1fr;
            }

            .moreOptions {
                justify-self: center;
            }
        }
    }

    &.wide {
        @media (--lgUp) {
            .actions {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}

.icon {
    vertical-align: bottom;
}

.media {
    background: var(--gray7);

    @media (--mdUp) {
        overflow: hidden;
        border-radius: 0 0 8px 8px;
    }

    @media (--lgUp) {
        &.default {
            border-radius: 8px;
        }
    }
}

.image {
    vertical-align: middle;
}
