.root {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

.title {
    margin-bottom: calc(2 * var(--spacing));

    @media (--lgUp) {
        text-align: right;
    }
}
