.root {
    display: grid;
    gap: calc(3 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    & > :nth-child(n + 2) {
        display: none;
    }

    @media (--mdUp) {
        grid-template-columns: 1fr 1fr;

        & > :nth-child(-n + 3) {
            display: flex;
        }

        & > :nth-child(n + 3) {
            display: none;
        }
    }

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr 1fr;

        & > :nth-child(n + 1) {
            display: flex;
        }
    }
}

.category {
    display: flex;
    height: 300px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: calc(4 * var(--spacing)) calc(3 * var(--spacing));
    background-color: #f5f5f5;
    color: var(--gray1);

    &.image {
        border-radius: 3px;
        background-position: center;
        background-size: cover;
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    }
}
