.container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: calc(6 * var(--spacing));

    @media (--mdUp) {
        grid-template-columns: 2.5fr 7fr 2.5fr;

        & > * {
            grid-area: auto / 2;
        }
    }
}
