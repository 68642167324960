.root {
    display: flex;
    flex-direction: column;
    gap: calc(4 * var(--spacing));
    @mixin small;
    @mixin w400;
}

.item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}
