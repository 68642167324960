.button {
    overflow: visible;
}

.badge {
    position: absolute;
    top: -8px;
    right: -8px;
}

.sidePanel {
    display: flex;
    width: 500px;
    flex-direction: column;
    background: var(--surface);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(3 * var(--spacing));
        color: var(--primaryColor);
        gap: calc(3 * var(--spacing));

        .group {
            display: flex;
            align-items: center;
            gap: calc(3 * var(--spacing));
        }
    }

    .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: calc(3 * var(--spacing));
        overflow-y: auto;
        overscroll-behavior-y: none;

        .comments {
            display: flex;
            flex-direction: column;
        }

        .loadMoreButton {
            padding: calc(3 * var(--spacing)) calc(3 * var(--spacing)) 0;
        }

        .overlay {
            position: absolute;
            z-index: var(--zIndexOverlay);
            top: 80px;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: calc(3 * var(--spacing));
            backdrop-filter: blur(16px) brightness(100%);
            color: var(--primaryColor);
        }
    }

    .footer {
        padding: calc(3 * var(--spacing));
        margin-top: calc(3 * var(--spacing));
    }
}
