.root {
    padding-top: calc(3 * var(--spacing));
    padding-bottom: calc(3 * var(--spacing));
    background: var(--surface);
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc(6 * var(--spacing));
}

.information {
    display: none;

    @media (--mdUp) {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
    }
}

.deadline {
    color: var(--gray2);
}

.actions {
    flex: 1;

    @media (--mdUp) {
        flex-direction: row-reverse;
        align-items: center;
    }
}

.moreOptions {
    display: none;

    @media (--xlUp) {
        display: inline-flex;
    }
}
