.item {
    display: flex;
    flex-direction: row;
    gap: var(--spacing);
}
.icon {
    margin: auto 0;
    color: var(--gray3);
    cursor: pointer;
    &:hover {
        color: var(--primaryColor);
    }

    @mixin w400;
}
