.cards {
    display: grid;
    gap: calc(3 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    @media (--mdUp) {
        grid-template-columns: 1fr 1fr;
    }

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--xlUp) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.pagination {
    margin-top: calc(3 * var(--spacing));
}

.noResults {
    margin-top: calc(20 * var(--spacing));
    color: var(--gray5);
}
