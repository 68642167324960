.root {
    --spacing3: calc(3 * var(--spacing));
    --spacing6: calc(6 * var(--spacing));
    position: sticky;
    z-index: var(--zIndexOverlay);
    bottom: 0;
    padding: var(--spacing6) 0 var(--spacing3);
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) var(--spacing3)
    );

    @media (--lgUp) {
        padding: calc(12 * var(--spacing)) 0 var(--spacing6);
    }
}

.container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (--mdUp) {
        grid-template-columns: 2.5fr 7fr 2.5fr;
    }
}

.buttons {
    @media (--smUp) {
        /* Composing doesn't work inside media queries */
        flex-wrap: nowrap;

        button {
            width: 100%;
            min-width: 0;
            flex: 0 1 auto;
        }
    }

    @media (--mdUp) {
        grid-area: auto / 2;
    }
}
