.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(2 * var(--spacing));
}

.button {
    @mixin small;
    margin-bottom: calc(4 * var(--spacing));
}
