.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(2 * var(--spacing));
}

.name {
    overflow-wrap: break-word;
    text-align: center;
}
