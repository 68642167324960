.root {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing)) calc(3 * var(--spacing));
}

.link {
    align-self: center;
}

.toggle {
    white-space: nowrap;

    .moreOptions {
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    &:hover .moreOptions {
        text-decoration-thickness: 2px;
    }
}
