.icon {
    margin-right: var(--spacing);
}

.info {
    margin: calc(3 * var(--spacing)) 0;
}

.card {
    margin-bottom: calc(3 * var(--spacing));
}

.compliance {
    display: flex;
    flex-direction: column;
    padding-left: calc(3.5 * var(--spacing));
    gap: var(--spacing);
    list-style: decimal;

    a:hover {
        text-decoration: none;
    }
}
