.title {
    margin-bottom: calc(3 * var(--spacing));
}

.link {
    font-size: 1rem;
}

.operations {
    display: flex;
    flex-direction: column;
    gap: calc(6 * var(--spacing));
}

.description {
    margin-bottom: calc(3 * var(--spacing));
}
