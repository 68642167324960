.root {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-radius: 100%;
}

.hiddenFileInput {
    display: none;
}

.actions {
    position: absolute;
    z-index: var(--zIndexOverlay);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    color: var(--primaryColor);
    gap: var(--spacing);
    opacity: 0;
    transition: opacity 200ms var(--fastOutSlowIn);

    &:hover {
        opacity: 1;
    }
}
