.wrapper {
    display: grid;
    min-height: 730px;
    background-color: #fff;
    grid-template-columns: minmax(0, 1fr);

    @media (--mdUp) {
        grid-template-columns: 4fr 8fr;
    }
}

.leftColumn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    background: var(--primaryColor);

    @media (--mdUp) {
        flex-direction: column;
    }
}

.rightColumn {
    display: flex;
    flex-direction: column;
    padding: calc(3 * var(--spacing));

    @media (--mdUp) {
        align-items: center;
    }
}

.logoWrapper {
    display: flex;
    padding: calc(3 * var(--spacing));

    @media (--mdDown) {
        width: 50%;
        justify-content: flex-end;
        order: 2;
    }
}

.logo {
    height: 20px;
    float: left;
}

.hint {
    padding-right: calc(6 * var(--spacing));
    padding-left: calc(6 * var(--spacing));

    @media (--mdDown) {
        width: 100%;
        order: 3;
        padding-right: calc(3 * var(--spacing));
        padding-bottom: calc(3 * var(--spacing));
        padding-left: calc(3 * var(--spacing));
    }
}

.headline {
    margin: 0;
    color: #fff;
}

.navigation {
    padding: calc(3 * var(--spacing));

    @media (--mdDown) {
        width: 50%;
        order: 1;
    }
}

.form {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @media (--lgUp) {
        width: 450px;
        margin-top: calc(3 * var(--spacing));
    }
}

.fields {
    display: grid;
    height: 100%;
    gap: calc(2 * var(--spacing));
    grid-template-columns: 1fr;
    place-content: flex-start;

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr;
    }
}

.filePreview {
    display: flex;
    align-items: center;
    padding: var(--spacing);
    border: 1px solid var(--gray6);
    border-radius: 3px;
    gap: calc(2 * var(--spacing));
}

.fileThumbnail {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.fileName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fileDeleteButton {
    margin: 0 var(--spacing) 0 auto;
}

.fileInput {
    display: none;
}

.colspan {
    min-width: 0;
    grid-column: 1 / -1;
}

.actions {
    margin-top: calc(3 * var(--spacing));
}

.radioButtons {
    margin-bottom: calc(3 * var(--spacing));
}
