.root {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
}

.header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);

    @media (--smUp) {
        flex-direction: row;
        justify-content: space-between;
        gap: calc(2 * var(--spacing));
    }
}

.stepper {
    margin-left: 37px;

    @media (--smUp) {
        margin-left: 0;
    }
}

.title {
    display: inline-flex;
    flex-wrap: wrap;
    gap: calc(0.5 * var(--spacing));
}

.bookingOperations {
    display: flex;
    flex-direction: column;
    margin-left: 37px;
    gap: calc(3 * var(--spacing));
}

.inputFieldsTitle {
    margin-top: var(--spacing);
    @mixin small;
    @mixin w500;
}
.inputFields {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}
