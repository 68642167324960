.root {
    display: flex;
    align-items: center;
    gap: calc(2 * var(--spacing));

    @media (--lgUp) {
        flex-direction: row-reverse;
        text-align: right;
    }
}

.media {
    width: 40px;
    height: 40px;
    flex: none;
}
