.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(3 * var(--spacing));
    gap: calc(1.5 * var(--spacing));
}

.link {
    text-align: center;
}
