.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing);
}

.label {
    display: flex;
    justify-content: space-between;
    gap: calc(2 * var(--spacing));
}

.button {
    @mixin small;
}
