.speaker {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(2 * var(--spacing));
    text-align: center;
}

.social {
    display: flex;
    align-items: flex-end;
    gap: calc(2 * var(--spacing));
}

.details {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}
