.root {
    display: grid;
    margin-bottom: calc(3 * var(--spacing));
    gap: calc(3 * var(--spacing));
    grid-template-columns: 1fr 1fr;

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.link {
    position: relative;
    display: block;
    overflow: hidden;
    min-width: 0;
    border-radius: 8px;
    background: var(--gray6);

    &:hover .content {
        opacity: 0.9;
    }
}

.image {
    display: block;
    width: 100%;
}

.content {
    position: absolute;
    top: 50%;
    right: calc(3 * var(--spacing));
    left: 0;
    padding: calc(1.5 * var(--spacing)) calc(3 * var(--spacing));
    border-radius: 0 8px 8px 0;
    background: #fff;
    opacity: 0.85;
    transform: translateY(-50%);
    transition: opacity 200ms var(--fastOutSlowIn);
}
