.root {
    position: relative;
    display: flex;
    flex-direction: column;
}

.badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: var(--primaryColorLight);

    &.invited {
        background: var(--purple);
    }
}
